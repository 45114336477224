import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UseModalProps,
  Button,
  HStack,
  VStack,
  Input,
  FormLabel,
  FormErrorMessage,
  FormControl,
  Alert,
  AlertIcon,
  AlertDescription,
  Select,
  Switch,
  Box,
  Flex,
  SimpleGrid,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { DatePicker } from '@orange_digital/chakra-datepicker';
import { useNavigate } from 'react-router-dom';

import { usePatientStore } from '@/stores/patients';

import { VVNotification } from '../types';

import { json } from 'msw/lib/types/context';

export interface UseVVNotificationModalProps extends UseModalProps {
  notification?: VVNotification | null;
}

export const VVNotificationModal: React.FC<UseVVNotificationModalProps> = ({
  isOpen,
  onClose,
  notification,
}) => {
  const newPatientDefaults = {
    user_name: '',
    custom_id: '',
    dob: '',
    dominant_eye: '',
    gender: '',
    ethnicity: '',
    has_amblyopia: false,
    has_strabismus: false,
    notes: '',
  };

  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent maxW="56rem">
          <ModalHeader>{notification?.subject}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{ __html: notification?.body ? notification?.body : '' }}
            ></div>
          </ModalBody>
          <ModalFooter justifyContent="space-between"></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
