import { Box, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';

import { Head } from '../Head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
  subtitle?: any;
};

export const ContentLayout = ({ children, title, subtitle }: ContentLayoutProps) => {
  return (
    <>
      <Box p={4} pb={0}>
        {/* <Box px={4} py={2} background="blue.800" width="full" roundedBottom="md">
          <Box background="white" width="full" rounded="md">
            <Head title={title} />
            <div className="p-4 py-2 md:p-4 md:py-2"> */}
        <Heading fontSize={['md', 'xl', '2xl']} color="blue.800" fontWeight="semibold">
          {title}
        </Heading>
        {subtitle && (
          <Text fontSize={['xs', 'sm']} fontWeight="normal">
            {subtitle}
          </Text>
        )}
        {/* </div>
          </Box>
        </Box> */}
      </Box>
      {/* <Box px={4} pt={4}>
        <Box background="white" width="full" rounded="md">
          <Head title={title} />
          <div className="p-6 py-4 md:p-8">
            <Heading fontSize={['sm', '2xl', '4xl']} color="blue.700">
              {title}
            </Heading>
          </div>
        </Box>
      </Box> */}

      <Box p={4}>
        <Box background="white" width="full" rounded="md">
          <Box p={[3, 6, 8]}>
            {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">{children}</div> */}
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};
