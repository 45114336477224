import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { ClinicDevicesData } from './getClinicDevices';

export type SetAssignHeadsetResponse = {
  success?: string;
  error?: string;
  clinic_devices?: ClinicDevicesData;
};

export type SetAssignHeadsetDTO = {
  clinic_device_id: number;
  clinic_user_id: number;
  device_alias?: string;
};

export const setAssignHeadset = ({
  clinic_device_id,
  clinic_user_id,
  device_alias,
}: SetAssignHeadsetDTO): Promise<SetAssignHeadsetResponse> => {
  if (device_alias) {
    return axios.post('/vue_api/assign_clinic_device', {
      clinic_device_id,
      clinic_user_id,
      device_alias,
    });
  }
  return axios.post('/vue_api/assign_clinic_device', {
    clinic_user_id: clinic_user_id,
    clinic_device_id: clinic_device_id,
  });
};

type UseSetAssignHeadsetOptions = {
  config?: MutationConfig<typeof setAssignHeadset>;
};

export const useSetAssignHeadset = ({ config }: UseSetAssignHeadsetOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (data) => {
      await queryClient.cancelQueries('clinic_devices');

      const previousClinicDevices = queryClient.getQueryData<ClinicDevicesData>(['clinic_devices']);

      // queryClient.setQueryData('clinic_devices');

      return { previousClinicDevices };
    },
    onError: (_, __, context: any) => {
      if (context?.previousClinicDevices) {
        queryClient.setQueryData('clinic_devices', context.previousClinicDevices);
      }
    },
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries(['clinic_devices']);
      if (data.success) {
        addNotification({
          type: 'success',
          title: 'Headset settings have been updated',
        });
      } else {
        addNotification({
          type: 'error',
          title: 'There was a problem trying to update the Headset',
        });
      }
      queryClient.setQueryData('clinic_devices', data.clinic_devices);
    },
    ...config,
    mutationFn: setAssignHeadset,
  });
};
