import { useAuth } from '@/lib/auth';
import { useNavigate, Navigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();

  // redirect to app dashboard if already logged in
  const auth = useAuth();
  if (auth.user) return <Navigate to={{ pathname: '/app' }} />;

  return (
    <Layout title="Log In | Vivid Vision Perimetry">
      <LoginForm onSuccess={() => navigate('/app')} />
    </Layout>
  );
};
