import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

export type VVPProductsTypeData = { [product_name: string]: string };

export type VVPProductsTypes = { [type: string]: VVPProductsTypeData };

type GetVVPProductsResponse = {
  products: VVPProductsTypes;
  error?: string;
  success?: string;
};

export const getVVPProducts = (): Promise<VVPProductsTypes> => {
  return axios.post('/vue_api/get_vvp_products', {}).then((resp: GetVVPProductsResponse | any) => {
    const data: VVPProductsTypes = resp.products;
    return data;
  });
};

type UseVVPProductsOptions = {
  config?: QueryConfig<typeof getVVPProducts>;
};

export const useVVPProducts = ({ config }: UseVVPProductsOptions) => {
  return useQuery({
    ...config,
    cacheTime: 0,
    refetchInterval: 5000,
    queryKey: ['vvp_products'],
    queryFn: () => getVVPProducts(),
  });
};
