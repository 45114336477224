import { useQuery } from 'react-query';
import { StringLiteralLike } from 'typescript';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

import { VVNotification } from '../types';

type GetVVNotificationsResponse = {
  notifications: VVNotification[];
  error?: string;
  success?: string;
};

export type VVNotificationsData = VVNotification[];

export const getVVNotifications = (): Promise<VVNotificationsData> => {
  return axios
    .post('/vue_api/get_notifications', {})
    .then((resp: GetVVNotificationsResponse | any) => {
      const data: VVNotificationsData = resp.notifications;
      return data;
    });
};

// type UseVVNotificationsOptions = {
//   config?: QueryConfig<typeof getVVNotifications>;
// };

export const useVVNotifications = () => {
  return useQuery({
    cacheTime: 0,
    queryKey: ['notifications'],
    queryFn: () => getVVNotifications(),
    refetchInterval: 1000 * 60,
    refetchIntervalInBackground: false,
  });
};
