import { ChakraProvider } from '@chakra-ui/react';
import { theme as defaultTheme } from '@chakra-ui/theme';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Button, Spinner } from '@/components/Elements';
import { Notifications } from '@/components/Notifications/Notifications';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';
import { useAppStore } from '@/stores/app';

import { theme } from '../chakra.theme';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

type SubdomainProviderProps = {
  children: React.ReactNode;
};

export const SubdomainProvider = ({ children }: SubdomainProviderProps) => {
  const { setSubdomain } = useAppStore();

  useEffect(() => {
    const host = window.location.host;

    const arr = host.split('.').slice(0, host.includes('localhost') ? -1 : -2);
    if (arr.length > 0) setSubdomain(arr[0]);
    console.log(arr[0]);
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
    </React.Suspense>
  );
};
