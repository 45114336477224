import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

export type ClinicDevicesRow = {
  doctor_id: number;
  clinic_user_id: number;
  device_id: string;
  device_serial_number: string;
  device_alias: string;
  allow_profile_switching: boolean;
  sub_token_url: string;
  timestamp: number;
  clinic_device_id: number;
};

type GetClinicDevicesResponse = {
  clinic_devices: ClinicDevicesRow[];
  error?: string;
  success?: string;
};

export type ClinicDevicesData = ClinicDevicesRow[];

export const getClinicDevices = (): Promise<ClinicDevicesData> => {
  return axios
    .post('/vue_api/get_clinic_devices', {})
    .then((resp: GetClinicDevicesResponse | any) => {
      const data: ClinicDevicesData = resp.clinic_devices;
      return data;
    });
};

type UseClinicDevicesOptions = {
  config?: QueryConfig<typeof getClinicDevices>;
};

export const useClinicDevices = ({ config }: UseClinicDevicesOptions) => {
  return useQuery({
    ...config,
    cacheTime: 0,
    refetchInterval: 5000,
    queryKey: ['clinic_devices'],
    queryFn: () => getClinicDevices(),
  });
};
