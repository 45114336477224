import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { VVNotification } from '../types';

export type VVNotificationsData = VVNotification[];

export type SetNotificationIsReadResponse = {
  notification: VVNotification;
  error?: string;
  success?: string;
};

export type SetNotificationIsReadDTO = {
  notification_id: number;
  is_read: boolean;
};

export const setNotificationIsRead = ({
  notification_id,
  is_read,
}: SetNotificationIsReadDTO): Promise<SetNotificationIsReadResponse> => {
  console.log('posting to /vue_api/set_notification_is_read', {
    notification_id,
    is_read,
  });
  return axios.post('/vue_api/set_notification_is_read', {
    notification_id,
    is_read,
  });
};

type UseSetNotificationIsReadOptions = {
  config?: MutationConfig<typeof setNotificationIsRead>;
};

export const useSetNotificationIsRead = ({ config }: UseSetNotificationIsReadOptions = {}) => {
  // const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (data) => {
      await queryClient.cancelQueries('notifications');

      const previousNotifications = queryClient.getQueryData<VVNotification[]>(['notifications']);

      const n = previousNotifications?.find(
        (notification) => notification.notification_id === data.notification_id
      );

      if (n) n.is_read = data.is_read;

      queryClient.setQueryData('notifications', previousNotifications);

      return { previousNotifications };
    },
    onError: (_, __, context: any) => {
      if (context?.previousTestConfig) {
        queryClient.setQueryData('notifications', context.previousNotifications);
      }
    },
    // onSuccess: (data, vars) => {
    //   queryClient.invalidateQueries(['saSettings', vars.clinic_user_id]);
    //   if (data.success) {
    //     addNotification({
    //       type: 'success',
    //       title: 'Smart Assist Settings updated',
    //     });
    //   } else {
    //     addNotification({
    //       type: 'error',
    //       title: 'There was a problem trying to update Smart Assist Settings',
    //     });
    //   }
    // },
    ...config,
    mutationFn: setNotificationIsRead,
  });
};
